import { Box, Typography } from '@mui/material';
import React from 'react';

// IMAGES
import trabajadores_euro from '../../../images/imgs/trabajadores_euro.png'
import bullets_euro from '../../../images/gifs/bullets_euro.gif'

export const Informacion = () => {
  return (
    <Box
      id={'nosotros'}
      textAlign={'center'}
      style={{backgroundColor: '#292564ff'}}
    >
      <Typography 
        marginTop={10}
        fontSize={'7vw'}
        fontWeight='bolder'
        color={'white'} 
        fontFamily={'Montserrat'}
      >
          NOSOTROS
      </Typography>
      <Typography 
        color={'white'}
        fontSize={'1.4vw'}
        fontFamily={'Montserrat'}
      >
        Desde
      </Typography>
      {/* <Box
        marginTop={1}
        height={60}
        width={'100%'}
        style={{backgroundColor:'#fec02cff'}}
      > */}
        <Typography 
          fontSize={'6vw'}
          fontWeight={'bold'}
          color={'white'}
          fontFamily={'Montserrat'}
          // marginTop={-14}
        >
          1996
          <Box
            marginTop={-13}
            height={60}
            width={'100%'}
            style={{backgroundColor:'#fec02cff'}}
          />
        </Typography>
      {/* </Box> */}
      <Typography 
        marginTop={10}
        color={'white'}
        fontSize={'1.4vw'}
        fontFamily={'Montserrat'}
      >
        Asesoramos, comercializamos, instalamos y entregamos a punto 
        todos los equipos de transportación vertical para personas y/o carga.
      </Typography>
      <img 
        width={'60%'}
        src={trabajadores_euro}
      />
      <Typography 
        color={'white'}
        fontFamily={'Montserrat'}
        fontSize={'3vh'}
        marginTop={'1%'}
      >
        Con casi 30 años de experiencia en el sector, somos una empresa 
        mexicana comprometida con la seguridad de los viajeros y la operación 
        de equipos de transportación vertical para personas y/o carga. 
        Hemos instalado y entregado miles de unidades en todo el país, ofreciendo 
        un servicio completo que comprende asesoramiento, comercialización e instalación.
      </Typography>
      <Typography 
        variant='h2' 
        color={'white'}
        fontFamily={'Montserrat'}
        marginTop={'4%'}
        fontWeight={'600'}
      >
        ASCENSORES PARA LA VIDA
      </Typography>
      <img
        style={{
          width:'100%',
          boxShadow:'0px 10px 10px rgba(0, 0, 0, 0.3)'
        }}
        src={bullets_euro}
      />
    </Box>
  );
};
