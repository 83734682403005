import React from 'react';
import { Box, Stack } from "@mui/material";
import { Header } from '../components/paginaPublica/1header/Header';
import { Principal } from '../components/paginaPublica/2principal/Principal';
import { Informacion } from '../components/paginaPublica/3informacion/Informacion';
import { Productos } from '../components/paginaPublica/4productos/Productos';
import { Personaliza } from '../components/paginaPublica/5personaliza/Personaliza';
import { Cont } from '../components/paginaPublica/6cont/Cont';
import { Proyectos } from '../components/paginaPublica/7proyectos/Proyectos';
import { Contacto } from '../components/paginaPublica/8contacto/Contacto';
import { Footer } from '../components/paginaPublica/9footer/Footer';

export const PaginaPublica = () => {
  return (
    <div>
      <Header/>
      <Stack>
        <Principal/>
        <Informacion/>
        <Productos/>
        <Personaliza/>
        <Cont/>
        <Proyectos/>
        <Contacto/>
      </Stack>
      <Footer/>
    </div>
  );
};
