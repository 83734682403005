import { Box, Grid, Link, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';

// IMAGES
import logo_orona from '../../../images/logos/logo_orona.png'
import logo_eleser from '../../../images/logos/logo_eleser.png'
import logo_canny from '../../../images/logos/logo_canny.png'

export const Productos = () => {
  return (
    <div
    style=
    {
        {
            backgroundImage: "url(../img/FONDO.svg)", 
            width: "100%", 
            height: "100vh", 
            backgroundSize: "cover",
        }
    } 
    
    >
    <Box
    p={20}
    >
      <Stack 
        direction="row" 
        spacing={2} 
        justifyContent="space-between"
      >
        <Box 
          p={7} 
          width={700} 
        >
        <Typography
          textAlign={'center'}
          color={"#292564ff"}
          fontWeight={800}
          fontSize={30}
          fontFamily={'Montserrat'}
        > 
          SOMOS DISTRIBUIDORES DE LAS PRINCIPALES MARCAS MUNDIALES
        </Typography>
        <Box
        height={'5%'}
          style={{
            backgroundColor:'#fec02cff'
          }}
        >

          
        </Box>
        <Typography
          textAlign={'center'}
          marginTop={2}
          fontSize={20}
          fontFamily={'Montserrat'}
        >Empresas certificadas, que cumplen la normativa más exigente a nivel mundial..</Typography>
        </Box>
        <Box display={'flex'} 
          
        >
          <Box
            borderRadius={8}
            width={'11vw'}
            height={'8vw'}
            
            sx={{
              background:'gray',
              marginRight:'2vw'
            }}
          >
            <Link
              target='_blank'
              href='https://www.orona-group.com/int-es/'
            >
              <img 
                width={'98%'}
                style={{
                  marginTop:"15%"
                }}
                src={logo_orona}/>
            </Link>
          </Box>
          <Box
            borderRadius={8}
            width={'11vw'}
            height={'8vw'}
            sx={{
              background:'gray',
              marginRight:'2vw'
            }}
          >
            <Link
              target='_blank'
              href='https://www.eleser.es/'
            >
              <img
                width={'111%'}
                style={{
                  marginTop:"15%",
                  
                }} 
                src={logo_eleser}/>
              
            </Link>
          </Box>
          <Box
            borderRadius={8}
            width={'11vw'}
            height={'8vw'}
            sx={{
              background:'gray'
            }}
          >
            <Link
              target=':blank'
              href='http://en.canny-elevator.com/'
            >
              <img
                width={'100%'}
                style={{
                  marginTop:"15%",
                  
                }} 
                src={logo_canny}/>
            </Link>
          </Box>
        </Box>
      </Stack>
      <Typography 
        color={"#292564ff"}
        fontSize={"5.5vw"}
        fontWeight={'bolder'}
        fontFamily={'Montserrat'}
        textAlign={'center'}
      >PRINCIPALES PRODUCTOS</Typography>
      <Stack
      marginLeft={'15%'}
        display={'flex'}
        direction="row" 
        fontFamily={'Montserrat'}
      >
        <List
          
          style={{
            marginRight:"20%"
          }}
        >
          <ListItem>
          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
            <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.2vw'}
                          > 
                          ELEVADORES SIN CUARTO DE MÁQUINAS
                          </Typography>
          </ListItem>
          <ListItem>
          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            
                            }}
                          />
            <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.2vw'}
                          > 
                           ELEVADORES CON SALA DE MÁQUINAS
                          </Typography>
            
          </ListItem>
          <ListItem>
          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
          <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.2vw'}
                          > 
                           ASCENSORES DE VELOCIDAD ALTA Y MEDIA
                          </Typography>
         
          </ListItem>
        </List>
        <List>
          <ListItem>
          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
            <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.2vw'}
                          > 
                            MONTA COCHES Y MONTACARGAS
                          </Typography>
          </ListItem>
          <ListItem>
          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
            <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.2vw'}
                          > 
                           EQUIPOS PANORÁMICOS
                          </Typography>
            
          </ListItem>
          <ListItem>
          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
          <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.2vw'}
                          > 
                           ASCENSORES DE VELOCIDAD ALTA Y MEDIA
                          </Typography>
          
          </ListItem>
        </List>
        
      </Stack>
    </Box>
  </div>
  );
};

