import React from 'react';
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// IMAGES
import logo_euro from '../../../images/logos/logo_euro.png'

export const Header = () => {
    function enviarWhatsapp() {
        const phoneNumber = '55 4359 3252';
        const message = 'Hola quiero recibir informacion';
        window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
      }
  return (
        <AppBar  sx={{paddingTop: 1, paddingBottom: 1, background: 'white', top:'0', position:'fixed'}}>
            <Toolbar >
                <Box sx={{ flexGrow: 1}}>
                    <a href='#inicio'>
                        <Button>
                            <img 
                                src={logo_euro} 
                                style={{
                                    width:"10vw",
                                    maxWidth:"100%",
                                    height:'auto'
                                }}
                            />
                        </Button>
                    </a>
                </Box>
                <Box sx={{flexGrow: 1}}>            
                    <a href='#nosotros'>
                        <Button color='secondary' sx={{marginRight: '2vw'}}>
                            <Typography 
                                fontFamily={'Montserrat'}
                                sx={{fontSize: '1.1vw', fontWeight: 700}}
                            >
                                NOSOSTROS
                            </Typography>
                        </Button>
                    </a>
                    <a href='#proyectos'>
                        <Button color='secondary'>
                            <Typography 
                                fontFamily={'Montserrat'}
                                sx={{fontSize: '1.1vw', fontWeight: 700}}
                            >
                                PROYECTOS
                            </Typography>
                        </Button>
                    </a>
                    <a href='#contacto'>
                        <Button color='secondary' sx={{marginLeft: '2%'}}>
                            <Typography 
                                fontFamily={'Montserrat'}
                                sx={{fontSize: '1.1vw', fontWeight: 700}}
                            >
                                    CONTACTO
                            </Typography>
                        </Button>
                    </a>
                    <Button 
                        color='secondary'
                        onClick={enviarWhatsapp} 
                        sx={{marginLeft: '2%'}}
                    >
                        <Typography 
                            fontFamily={'Montserrat'}
                            sx={{fontSize: '1.1vw', fontWeight: 700}}
                        >
                            COTIZA AHORA
                        </Typography>
                    </Button>
                </Box>
                <Box textAlign={'right'}>
                    <a href='https://www.facebook.com/eurosacensoresmx/' target="_blank">
                        <IconButton color='secondary' size='large'>
                            <FacebookIcon/>
                        </IconButton>
                    </a>
                    <a href='https://instagram.com/euro.ascensores?igshid=YmMyMTA2M2Y=' target="_blank">
                        <IconButton color='secondary'>
                            <InstagramIcon/>
                        </IconButton>
                    </a>
                    <a href='https://www.linkedin.com/in/euro-ascensores-3b562a200/' target="_blank">
                        <IconButton color='secondary'>
                            <LinkedInIcon/>
                        </IconButton>
                    </a>
                </Box>
            </Toolbar>
        </AppBar>
    );
};
