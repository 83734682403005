import React, { Fragment } from "react";
import { PaginaPublica } from './templates/PaginaPublica';

function App() {
  return (
    <Fragment>
      <PaginaPublica/>
    </Fragment>
  );
};
export default App;
