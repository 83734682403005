import { Box, Button, Link, Slider, Typography } from '@mui/material';
import { WhatsApp} from "@mui/icons-material"
import React from 'react';

// IMAGES
import euro_bn from '../../../images/logos/logo_euro_bn.png'
import whatsapp_az from '../../../images/logos/logo_whatsapp_az.png'
import logo_orona from '../../../images/logos/logo_orona.png'
import img_personaliza from '../../../images/elementos/personaliza.png'

export const Personaliza = () => {
  function enviarWhatsapp() {
    const phoneNumber = '55 4359 3252';
    const message = 'Hola, quiero solicitar una cotización';
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
  }

  
  return (
    <div
    style=
    {
        {
            backgroundImage: `url(${img_personaliza})`,
            width: "100%", 
            height: "100vh", 
            backgroundSize: "cover",
            
        }
    } 
    >     
    <Box
      p={10}
      textAlign={'center'}
    
      
    >
      <Typography 
        color={'white'}
        fontSize={'8.7vw'}
        fontWeight={'1000'}
        fontFamily={'Montserrat'}
        style={{
          textShadow:'2px 2px 4px #000000'
        }}
      >
        PERSONALIZA
        
      </Typography>
      <Typography 
        color={'white'}
        fontSize={'2.7vw'}
        fontWeight={'1000'}
        textAlign={'right'}
        fontFamily={'Montserrat'}
      >
        TU ELEVADOR
      </Typography>
      <Box display={'flex'}>
        <img
          style={{
            width:'16%',
            height:'14%',
            marginTop:'20%'
          }}
          src={euro_bn}
        />
        <Button 
          variant="contained" 
          onClick={enviarWhatsapp}
          style={{
            marginTop:'22%',
            marginLeft:'25%',
            marginRight:'14%',
            width:'30%',
            borderRadius:200
          }}
        >
          <Typography fontFamily={'Montserrat'} fontWeight={'600'}>
            SOLICITA UNA COTIZACION 
          </Typography>
          
          <img
          style={{
            width:'27%',
            marginLeft:"10%",
            
          
          }}
          src={whatsapp_az}
        />
        </Button>
        <Link
              target='_blank'
              href='https://www.orona-group.com/int-es/'
            >
              <img 
                width={'70%'}
                style={{
                  
                  marginTop:"17vw"
                }}
                src={logo_orona}/>
            </Link>
      
      </Box>
    </Box>
    </div>  

  );
};

