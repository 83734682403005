import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions ,Typography, Button} from '@mui/material';
import React, { useState } from 'react';

// IMAGES
import logo_euro_bn from '../../../images/logos/logo_euro_bn.png'

export const Footer = () => {
  // const [open, setOpen] = useState(true);
  // const handleClose = () => {
  //   setOpen(false);
  // }

  // onClick dialog open
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <Box p={7} display={'flex'} style={{backgroundColor: '#311b92'}}>
      <Dialog 
        open={open}
        onClose={handleClickClose}
        // onClose={handleClose}
      >
        <DialogTitle> 
          Aviso de Ley de Cookies
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Este sitio web utiliza cookies para mejorar la experiencia de los usuarios. 
          Las cookies son pequeños archivos de texto que se descargan automáticamente 
          en tu dispositivo cuando visitas nuestro sitio web. Estas cookies nos permiten 
          reconocer tu dispositivo y personalizar tu experiencia en nuestro sitio web.

          Este sitio web utiliza las siguientes cookies:
            
          Cookies de sesión: Estas cookies son necesarias para el funcionamiento básico 
          de nuestro sitio web. Nos permiten recordar tus preferencias de idioma y 
          mantener tu sesión abierta mientras navegas por nuestro sitio web.
          Cookies de rendimiento: Estas cookies nos permiten recopilar información sobre 
          cómo utilizas nuestro sitio web. Por ejemplo, nos permiten saber qué páginas son 
          más populares, cuánto tiempo pasas en nuestro sitio web y cómo llegaste a nuestro 
          sitio web.
          Cookies de publicidad: Estas cookies se utilizan para personalizar la publicidad 
          en nuestro sitio web. Nos permiten mostrar anuncios relevantes y personalizados 
          para ti en función de tus intereses.
          Todas las cookies utilizadas en nuestro sitio web son de terceros y no recopilan 
          información personal identificable. No recopilamos ni almacenamos información 
          personal sobre nuestros usuarios.
            
          Al utilizar nuestro sitio web, aceptas el uso de cookies de acuerdo con esta política. 
          Si no deseas aceptar las cookies, puedes desactivarlas en la configuración de tu 
          navegador web. Ten en cuenta que si desactivas las cookies, es posible que no puedas 
          utilizar todas las funciones de nuestro sitio web.
            
          Si tienes alguna pregunta o comentario sobre nuestra política de cookies, contáctanos 
          a través de [correo electrónico].
            
          Este aviso de ley de cookies cumple con la normativa aplicable en México en materia de 
          privacidad y protección de datos personales.

          </DialogContentText>
        </DialogContent>

      </Dialog>
      <img 
        style={{width:'14%', height:'14%'}}
        // src="http://189.216.10.93/DriveDigitalizaMx/euro/logos/euro_bn.png"
        src={logo_euro_bn}
      />
      <Box display={'flex'} marginTop={'2%'} > 
        <Box
          display={'flex'}
          marginTop={'2%'}
        >
          <Typography
            fontSize={'1vw'}
            color={'whitesmoke'}
          >
            Euroascesores S.A de C.V
          </Typography>
        </Box>
        <Box
          textAlign={'right'}
          color={'white'}
          marginLeft={'59vw'}
        >
          <Button  
            onClick={handleClickOpen}
          >
            Términos y Condiciones
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
