import React from 'react';
import { Box , Button, ImageList, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem ,Typography } from '@mui/material';

//  IMAGES
import slider_inicial from '../../../images/gifs/slider_inicial.gif'

export const Principal = () => {
  function enviarWhatsapp() {
    const phoneNumber = '55 4359 3252';
    const message = 'Hola quiero recibir informacion';
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
  }
  return (
    <div
    id={'inicio'}
    style=
    {
        {
          backgroundImage: "url(../img/FONDO.svg)", 
            width: "100%", 
            height: "100vh", 
            backgroundSize: "cover",
        }
    } 
    >
      <Box display={'flex'} p={25}>        
        <Box>
          <Typography fontFamily={'Montserrat'} color='secondary' fontSize={'5vw'} fontWeight='bolder'>
            ¡Bienvenido a
          </Typography>
          <Typography fontFamily={'Montserrat'} color='secondary' fontSize={'5vw'} fontWeight='bolder' marginTop={'-4%'}>
            Euroascensores!
          </Typography>
          <Typography fontFamily={'Montserrat'} fontSize={'1.4vw'}>
            Somos líderes en México, comercializando equipo de movilidad vertical, elevadores, escaleras electricas, eleva autos y más de nuestro principal aliado comercial, Orona.
          </Typography>
          <List fontFamily='Montserrat'>
          <ListItem disablePadding>
                        <ListItemButton component="a" href="#home">
                          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
                          <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.4vw'}
                          > 
                            Nuevos proyectos
                          </Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="#simple-list">
                          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
                          <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.4vw'}
                          > 
                            Sustituciones
                          </Typography>
                        
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="#simple-list">
                          <img 
                            src="https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/61a62e1fe6a6d727ead75a3c6893537c.svg"
                            style={{
                              width:"1vw",
                              marginRight:"1vw"
                            }}
                          />
                          <Typography
                            fontFamily={'Montserrat'}
                            fontSize={'1.4vw'}
                          > 
                            Soluciones a edificios existentes sin elevadores
                          </Typography>
                        </ListItemButton>
                    </ListItem>
          </List>
          <Button 
            color='secondary' 
            variant="contained"
            onClick={enviarWhatsapp}
            style={{
              width:'40%',
              height:'10%',
              borderRadius:'200px',
              marginLeft:'40%'
            }}
          >
            <Typography  
              fontFamily='Montserrat'
              marginRight={3}>
              QUIERO RECIBIR INFORMACIÓN
            </Typography>
            <Box
          height={'60%'}
          marginRight={'10%'}
          style={{
            backgroundColor:'#fec02cff'
          }}
        >
          <Typography>.</Typography>
        </Box>
            <img 
            width={40}
            src='https://testpoliwingo.my.canva.site/ejemplo-web-euro/videos/21cf7ae03ce1a1628c96f8b173a39c31.gif'>
              
            </img>
        </Button>
        </Box>
        <Box 
        >
          <img
          style={{
            width:'100%',
            boxShadow:'0px 10px 10px rgba(0, 0, 0, 0.3)'
          }}
            src={slider_inicial}
          />
        </Box>
        
      </Box>
      
      </div>
     
    );
  };
  
