import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

// IMAGES
import logo_euro from '../../../images/logos/logo_euro.png'
// import logo_euro from '../../../images/logos/logo_euro.png'

export const Contacto = () => {
  return (
    <div
    id={'contacto'}
    style=
    {
        {
          backgroundImage: "url(../img/FONDO.svg)", 
            width: "100%", 
            height: "100vh", 
            backgroundSize: "cover",
        }
    } 
    >
    <Box
      display={'flex'}
      p={17}
    >
      <Box>
        <img 
          width={'100%'}
          src='https://testpoliwingo.my.canva.site/ejemplo-web-euro/images/22e12e43d455cbcc21dc1b6e4a4ef05d.jpg'/>
      </Box>
      <Box
      marginLeft={'2%'}
      >
      <img 
          src={logo_euro}
          style={{
                  width:"10vw",
                  textAlign:'left',
                  maxWidth:"100%",
                  height:'auto'
                }}
        />
        <Typography 
          fontSize={'2vw'}
          fontFamily={'Montserrat'}
          color={'#303030'}
        >
          TU SEGURIDAD NOS MUEVE Y NADA NOS DETIENE
        </Typography>
        <aside className="mapa">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.3934075364737!2d-99.03554912408606!3d19.524716637731316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1faa26e0801b7%3A0xe1306d051c29e8f5!2sElevadores%20Atlas%20M%C3%A9xico%20S.A.%20de%20C.V.!5e0!3m2!1ses-419!2smx!4v1681958384160!5m2!1ses-419!2smx" width="600" height="410" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        </aside>
        {/* <img
          style={{
            width:'15%',
            marginLeft:'100%'
          
          }}
          src='http://189.216.10.93/DriveDigitalizaMx/euro/elementos_web/whatsapp.png'
        /> */}
        
        {/* NUMEROS TELEFONICOS */}
        <Box marginTop={'2vh'}>
          <Typography color={'#303030'} fontSize={'1.4vw'}>
            <LocalPhoneIcon color='secondary'/> 57 494 444
          </Typography>
          <Typography color={'#303030'} fontSize={'1.4vw'}>
            <LocalPhoneIcon color='secondary'/> 800 242 8527
          </Typography>
        </Box>

        {/* REDES SOCIALES */}
        <Box textAlign={'center'}>
          <a href='https://www.facebook.com/eurosacensoresmx/' target="_blank">
            <IconButton color='secondary' size='large'>
              <FacebookIcon/>
            </IconButton>
          </a>
          <a href='https://instagram.com/euro.ascensores?igshid=YmMyMTA2M2Y=' target="_blank">
            <IconButton color='secondary'>
              <InstagramIcon/>
            </IconButton>
          </a>
          <a href='https://www.linkedin.com/in/euro-ascensores-3b562a200/' target="_blank">
            <IconButton color='secondary'>
              <LinkedInIcon/>
            </IconButton>
          </a>
        </Box>
        
      </Box>
      
    </Box>
    </div>

  );
};