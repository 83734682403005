import { Box,  Container, Grid, Link, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import React from 'react';

// IMAGES
import e1 from '../../../images/elementos/e1.png'
import e2 from '../../../images/elementos/e2.png'
import e3 from '../../../images/elementos/e3.png'
import e4 from '../../../images/elementos/e4.png'
import e5 from '../../../images/elementos/e5.png'
import e6 from '../../../images/elementos/e6.png'
import logo_atlas from '../../../images/logos/logo_atlas.png'

export const Cont = () => {
  return (
    <div
    style=
    {
        {
          backgroundImage: "url(../img/FONDO.svg)", 
            width: "100%", 
            height: "100vh", 
            backgroundSize: "cover",
            fontFamily:'Montserrat'
        }
    } 
    
    >
      <Box  
        style={{
          marginTop:"2vw",
          marginBottom:"5vw"
        }}
      >
        <Box>
          <Typography variant='h2' textAlign={'center'} marginBottom={'2vw'} fontFamily={'Montserrat'} style={{textShadow:'2px 2px 4px #000000'}}>
            ¿POR QUÉ ESCOGERNOS?
          </Typography>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Box sx={{width: '80%'}}>

          <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={4}> */}
          <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
            <Box borderRadius={8} p={4} style={{ backgroundColor: 'lightgray' }} sx={{height: '33vh'}}>
              <Box display={'flex'} justifyContent={'center'} marginBottom={'1vw'}>
                <img
                    src={e1}
                    style={{width:'5vw'}} 
                />
                <Typography 
                  fontSize={'1.2vw'}
                  marginTop={'0.5vw'}
                  fontWeight={'700'}
                  fontFamily={'Montserrat'}
                  sx={{ ml: 2 }}
                >
                  ASESORÍA GRATUITA
                </Typography>
              </Box>
              <Typography
                fontFamily={'Montserrat'}
                style={{textAlign: 'justify'}}
                fontSize={'1.7vh'}
              > 
                NUESTRO TRABAJO INICIA CON ASESORÍAS GRATUITAS QUE PERMITEN PROYECTAR ADECUADAMENTE ESPACIOS, CANTIDADES Y ESPECIFICACIONES DE LOS EQUIPOS DE TRANSPORTACIÓN VERTICAL 
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={3} sm={4}> */}
          <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
            <Box borderRadius={8} p={4} style={{backgroundColor: 'lightgray'}} sx={{height: '33vh'}}>
              <Box display={'flex'} justifyContent={'center'} marginBottom={'1vw'}>   
                <img
                  src={e2}
                  style={{width:'5vw'}}
                />
                <Typography 
                  fontSize={'1.2vw'}
                  marginTop={'0.5vw'}
                  fontWeight={'700'}
                  fontFamily={'Montserrat'}
                  sx={{ ml: 2 }}
                >
                  CORTOS TIEMPOS DE ENTREGA
                </Typography>
              </Box>
              <Typography fontFamily={'Montserrat'}
                style={{
                  textAlign: 'justify'
                }}
                fontSize={'1.7vh'}
              >
                SABEMOS EL SENTIDO DE URGENCIA EN ESTE TIPO DE PROYECTOS, POR LO QUE SIEMPRE PONDREMOS TODO NUESTRO ESFUERZO EN HACERTE ENTREGA EN EL MENOR TIEMPO POSIBLE
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={4}> */}
          <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
            <Box borderRadius={8} p={4} style={{backgroundColor: 'lightgray'}} sx={{height: '33vh'}}>
              <Box display={'flex'} justifyContent={'center'} marginBottom={'1vw'}>   
                <img
                  src={e3}
                  style={{width:'5vw'}}
                />
                <Typography 
                  fontSize={'1.2vw'}
                  marginTop={'0.5vw'}
                  fontWeight={'700'}
                  fontFamily={'Montserrat'}
                  sx={{ ml: 2 }}
                >
                  SOLUCIONES AMIGABLES
                </Typography>
              </Box>
              <Typography fontFamily={'Montserrat'}
                style={{
                  textAlign: 'justify'
                }}
                fontSize={'1.7vh'}
              >
                NUESTRAS SOLUCIONES SON INCLUSIVAS AL PENSAR EN LAS NECESIDADES DE TODOS, ADICIONAL CONTAMOS CON SOLUCIONES ESPECIFICAS PARA NECESIDADES PUNTUALES.
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={4}> */}
          <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
            <Box borderRadius={8} p={4} style={{backgroundColor: 'lightgray'}} sx={{height: '33vh'}}>
              <Box display={'flex'} justifyContent={'center'} marginBottom={'1vw'}>   
                <img
                  src={e4}
                  style={{width:'5vw'}}
                />
                <Typography 
                  fontSize={'1.2vw'}
                  marginTop={'0.5vw'}
                  fontWeight={'700'}
                  fontFamily={'Montserrat'}
                  sx={{ ml: 2 }}
                >
                  EQUIPOS IMPORTADOS
                </Typography>
              </Box>
              <Typography fontFamily={'Montserrat'}
                style={{
                  textAlign: 'justify'
                }}
                fontSize={'1.7vh'}
              >
                CONTAMOS CON LA EXPERTISE PARA REALIZAR EL PROCESO DE IMPORTACIÓN. GESTIONAMOS EL ENVÍO DEL FABRICANTE, HASTA EL PREDIO DE TU PROYECTO
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={4}> */}
          <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
            <Box borderRadius={8} p={4} style={{backgroundColor: 'lightgray'}} sx={{height: '33vh'}}>
              <Box display={'flex'} justifyContent={'center'} marginBottom={'1vw'}>   
                <img
                  src={e5}
                  style={{width:'5vw'}}
                />
                <Typography 
                  fontSize={'1.2vw'}
                  marginTop={'0.5vw'}
                  fontWeight={'700'}
                  fontFamily={'Montserrat'}
                  sx={{ ml: 2 }}
                >
                  INSTALACIONES SEGURAS
                </Typography>
              </Box>
              <Typography fontFamily={'Montserrat'}
                style={{
                  textAlign: 'justify'
                }}
                fontSize={'1.7vh'}
              >
                CONTAMOS CON PERSONAL ALTAMENTE CAPACITADO Y EXPERIMENTADO, CUMPLIENDO CON TODOS LOS REQUISITOS DE SEGURIDAD
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={4}> */}
          <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
            <Box borderRadius={8} p={4} style={{backgroundColor: '#ffb300'}} sx={{height: '33vh'}}>
              <Box display={'flex'} justifyContent={'center'} marginBottom={'1vw'}>   
                <img
                  src={e6}
                  style={{width:'5vw'}}
                />
                <Typography 
                  fontSize={'1.2vw'}
                  marginTop={'0.5vw'}
                  fontWeight={'700'}
                  fontFamily={'Montserrat'}
                  sx={{ ml: 2 }}
                >
                  MANTENIMIENTO EXTENDIDO INCLUIDO
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                <Typography fontFamily={'Montserrat'}
                  style={{
                    textAlign: 'justify'
                  }}
                  fontSize={'1.7vh'}
                  >
                  TODOS NUESTROS EQUIPOS INCLUYEN UN SERVICIO DE MANTENIMIENTO, BRINDADO POR NUESTRA EMPRESA HERMANA, ELEVADORES ATLAS DE MÉXICO
                </Typography>
                <Link 
                  target='_blank'
                  href='http://elevadoresatlas.com.mx/'
                >
                  <img 
                    style={{
                      width:'6vw'
                    }}
                    src={logo_atlas}
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>




          </Box>
        </Box>
      </Box>
    </div>
  );
};
  