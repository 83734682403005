import { Box, Button, Typography } from '@mui/material';
import { Padding, WhatsApp} from "@mui/icons-material"
import React from 'react';
import './proyecto.css'

// IMAGES
import p1 from '../../../images/proyectos/1.png'
import p2 from '../../../images/proyectos/2.png'
import p3 from '../../../images/proyectos/3.png'
import p4 from '../../../images/proyectos/4.png'
import p5 from '../../../images/proyectos/5.png'
import p6 from '../../../images/proyectos/6.png'
import p7 from '../../../images/proyectos/7.png'
import p8 from '../../../images/proyectos/8.png'
import p9 from '../../../images/proyectos/9.png'
import logo_whatsapp from '../../../images/logos/logo_whatsapp.png'
import euro_bn from '../../../images/logos/logo_euro_bn.png'

export const Proyectos = () => {
  function enviarWhatsapp() {
    const phoneNumber = '55 4359 3252';
    const message = 'Hola, quiero solicitar una cotización';
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
  }
  return (
    <Box
      p={15}
      id={'proyectos'}
      textAlign={'center'}
      style={{
        backgroundColor: '#292564ff',
      }}
    >
      <Typography 
        variant='h2'
        color={'white'}
        fontFamily={'Montserrat'}
        style={{
          textShadow:'2px 2px 4px #000000'
        }}
      >
        Proyectos
      </Typography>
      <div className='img_p'>
      <Box style={{
        backgroundImage: `url(${p1})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p2})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p3})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p4})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p5})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p6})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p7})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p8})`
      }}></Box>
      <Box style={{
        backgroundImage: `url(${p9})`
      }}>
        {/* <Typography
          color={'white'}
        >
          Hola
        </Typography> */}
      </Box> 
    </div>
    <Box
      display={"flex"}
      marginLeft={"10%"}
    >
      <Button 
        variant="contained" 
        onClick={enviarWhatsapp}
        style={{
          marginTop:'6%',
          width:'20%',
          borderRadius:200
        }}
      >
        <Typography fontFamily={'Montserrat'} fontWeight={'600'}>
          SOLICITA UNA COTIZACIÓN
        </Typography>
        <Box
          height={'60%'}
          marginRight={'10%'}
          style={{
            backgroundColor:'#292564ff'
          }}
        >
          <Typography>.</Typography>
        </Box>
        <img
          style={{
            width:'25%',
            marginRight:0,
          }}
          src={logo_whatsapp}
        />
      </Button>
      <img
        src={euro_bn}
        style={{
          width:"20%",
          height:"10%",
          marginTop:"4%",
          marginLeft:"50%"
        }}
      />
      </Box>
    </Box>
  );
};
